import getFilesAndDirectoriesFromDirectory from './getFilesAndDirectoriesFromDirectory.js';
/**
 * Interop between deprecated webkitGetAsEntry and standard getAsFileSystemHandle.
 */

function getAsFileSystemHandleFromEntry(entry, logDropError) {
  if (entry == null) return entry;
  return {
    // eslint-disable-next-line no-nested-ternary
    kind: entry.isFile ? 'file' : entry.isDirectory ? 'directory' : undefined,
    name: entry.name,

    getFile() {
      return new Promise((resolve, reject) => entry.file(resolve, reject));
    },

    async *values() {
      // If the file is a directory.
      const directoryReader = entry.createReader();
      const entries = await new Promise(resolve => {
        getFilesAndDirectoriesFromDirectory(directoryReader, [], logDropError, {
          onSuccess: dirEntries => resolve(dirEntries.map(file => getAsFileSystemHandleFromEntry(file, logDropError)))
        });
      });
      yield* entries;
    }

  };
}

async function* createPromiseToAddFileOrParseDirectory(entry, relativePath, lastResortFile) {
  if (lastResortFile === void 0) {
    lastResortFile = undefined;
  }

  // For each dropped item, - make sure it's a file/directory, and start deepening in!
  if (entry.kind === 'file') {
    const file = await entry.getFile();

    if (file !== null) {
      file.relativePath = relativePath ? `${relativePath}/${entry.name}` : null;
      yield file;
    } else if (lastResortFile != null) yield lastResortFile;
  } else if (entry.kind === 'directory') {
    for await (const handle of entry.values()) {
      yield* createPromiseToAddFileOrParseDirectory(handle, `${relativePath}/${entry.name}`);
    }
  } else if (lastResortFile != null) yield lastResortFile;
}

export default async function* getFilesFromDataTransfer(dataTransfer, logDropError) {
  const entries = await Promise.all(Array.from(dataTransfer.items, async item => {
    var _entry;

    const lastResortFile = item.getAsFile(); // Chromium bug, see https://github.com/transloadit/uppy/issues/3505.

    let entry; // IMPORTANT: Need to check isSecureContext *first* or else Chrome will crash when running in HTTP:
    // https://github.com/transloadit/uppy/issues/4133

    if (window.isSecureContext && item.getAsFileSystemHandle != null) entry = await item.getAsFileSystemHandle(); // fallback

    (_entry = entry) != null ? _entry : entry = getAsFileSystemHandleFromEntry(item.webkitGetAsEntry(), logDropError);
    return {
      lastResortFile,
      entry
    };
  }));

  for (const {
    lastResortFile,
    entry
  } of entries) {
    // :entry can be null when we drop the url e.g.
    if (entry != null) {
      try {
        yield* createPromiseToAddFileOrParseDirectory(entry, '', lastResortFile);
      } catch (err) {
        if (lastResortFile != null) {
          yield lastResortFile;
        } else {
          logDropError(err);
        }
      }
    } else if (lastResortFile != null) yield lastResortFile;
  }
}